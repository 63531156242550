import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
const userVuex = createNamespacedHelpers("user");
const taskVuex = createNamespacedHelpers("task");
export default {
  name: "tasks",
  data() {
    const nTaskList = [{
      label: this.$lang("Join channel"),
      target: "channel",
      icon: "tp"
    }, {
      label: this.$lang("Folow us on X"),
      target: "twitter",
      value: 1,
      icon: "twitter"
    }, {
      label: this.$lang("Connect wallet"),
      target: "wallet",
      icon: "ton"
    }];
    return {
      nTaskList,
      popBindWalletShow: false,
      taskNum: 0,
      extraTaskNum: 0
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "setting"]),
    ...userVuex.mapState(["userData"]),
    ...taskVuex.mapState(["taskList"])
  },
  methods: {
    ...walletVuex.mapActions(["loadTaskCount"]),
    ...userVuex.mapActions(["join"]),
    ...userVuex.mapMutations(["setUserData"]),
    ...taskVuex.mapActions(['getTaskList']),
    async onJoin(item) {
      if (item.target == "channel" || item.target == "telegram") {
        this.WebApp.openTelegramLink(this.setting.socials[item.target]);
      } else if (item.target == "wallet") {
        this.popBindWalletShow = true;
        return;
      } else {
        this.WebApp.openLink(this.setting.socials[item.target]);
      }
      if (!this.userData[`joined_${item.target}`]) {
        await this.join([item.target, null, 1]);
      }
    },
    async onExtralJoin(item, adv = 0) {
      console.log("url=====", item.url);
      if (item.type == 'telegram' || item.type == 'channel') {
        this.WebApp.openTelegramLink(item.url);
      }
      if (item.type == 'page') {
        this.$router.push(item.target);
      } else if ((item.type || '') == 'adsgram' || (item.provider || '') == 'adsgram') {
        this.$loading.show();
        const AdController = await window.Adsgram.init({
          blockId: item.url
        });
        await AdController.show().then(result => {
          // user watch ad till the end
          // your code to reward user
          this.$loading.hide();
          if (result.done && !item.completed) this.checkExtralTask(item, adv);
        }).catch(result => {
          // user skipped video or get error during playing ad
          // do nothing or whatever you want
          console.log(result);
          this.$loading.hide();
        });
        this.$loading.hide();
        return;
      } else if ((item.type || '') == 'matrix') {
        if (!item.completed) this.$router.push(`/taskDetail?id=${item.id}`);
      } else {
        this.WebApp.openLink(item.url);
      }
      this.checkNum = 0;
      if (!item.completed) this.checkExtralTask(item, adv);
    },
    async checkExtralTask(item, adv) {
      if (this.checkNum >= 5) {
        this.checkNum = 0;
        return;
      }
      this.checkNum++;
      let rs = await this.$http.post("/task/complete", {
        taskId: item.id,
        adv: adv
      });
      if (rs.code == 0) {
        item.completed = 1;
        if (adv == 1 && item.cyclical == 1) {
          item.countdown = item.cycle_interval;
        }
        let newUserData = {
          ...this.userData,
          ...(rs.data.userInfo ? rs.data.userInfo : rs.data)
        };
        this.setUserData(newUserData);
        if (adv == 0) {
          await this.loadTaskCount();
          this.checkTask();
        }
      } else if (rs.code == 302) {
        setTimeout(() => {
          this.checkExtralTask(item, adv);
        }, 2000);
      }
    },
    checkTask() {
      this.taskNum = 0;
      this.extraTaskNum = 0;
      if (!this.userData.joined_wallet) this.taskNum += 1;
      for (let key in this.setting.socials) {
        if (this.setting.socials[key]) {
          switch (key) {
            case "channel":
              if (this.userData.joined_channel == 0) {
                this.taskNum += 1;
              }
              break;
            case "discord":
              if (this.userData.joined_discord == 0) {
                this.taskNum += 1;
              }
              break;
            case "telegram":
              if (this.userData.joined_telegram == 0) {
                this.taskNum += 1;
              }
              break;
            case "twitter":
              if (this.userData.joined_twitter == 0) {
                this.taskNum += 1;
              }
              break;
          }
        }
      }
      this.taskList.map(item => {
        if (item.completed == 0) {
          this.extraTaskNum += 1;
        }
      });
    }
  },
  async mounted() {
    if (this.taskList.length == 0) await this.getTaskList();
    this.checkTask();
  }
};