var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "facescan",
    class: _vm.theme
  }, [_c('div', {
    staticClass: "facescan-main"
  }, [!_vm.loading ? _c('div', {
    staticClass: "fs-close",
    on: {
      "click": _vm.onClose
    }
  }, [_vm._v("×")]) : _vm._e(), _vm.loading ? _c('div', {
    staticClass: "fs-loading-spinner"
  }, _vm._l(8, function (item) {
    return _c('div', {
      staticClass: "fs-dot"
    });
  }), 0) : _vm._e(), _vm.iframeUrl ? _c('iframe', {
    staticClass: "iframe",
    attrs: {
      "id": "_skip",
      "src": _vm.iframeUrl,
      "allow": "microphone;camera;"
    },
    on: {
      "load": _vm.start
    }
  }) : _vm._e()])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };