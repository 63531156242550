import Vue from "vue";
import { TonConnectUI } from "@tonconnect/ui";
import { toUserFriendlyAddress } from "@tonconnect/sdk";

// import * as buffer from "buffer";

// if (typeof window.Buffer === "undefined") {
//   window.Buffer = buffer.Buffer;
// }
// import { getHttpEndpoint } from "@orbs-network/ton-access";
// import {  TonClient } from "@ton/ton";
import tonConfig from "@Web3WalletConfig/config.json";

const tonWallet = {
  connected: false, //是否已经连接上钱包 Boolean
  ton: null, //Object TonConnect api
  uiAddress: null, //UI显示地址
  address: null, //0:xxxx格式地址
  modal: null,
  wallet: null,
  walletInfo: null,
  isModal: false, //TonConnect 引导UI状态 显示隐藏状态 Boolean true：显示 false：隐藏
  account: null,
  chain: "",
  walletStateInit: null,
  publicKey: null,
  unsubscribe: () => {}, //订阅连接状态变化
  initTon: async function (id, bool) {
    return new Promise(async (resolve, reject) => {
      try {
        await tonWallet.createTonConnectUI(id);
        //await tonWallet.openOrCloseTonModal(bool);
        tonWallet.getWalletInfo();
        tonWallet.unsubscribeTonConnectStatus();
        // await tonWallet.getWalletBanlanceAndSequence();
        resolve(tonWallet);
      } catch (err) {
        console.log("initTon: ", err);
      }
    });
  },
  createTonConnectUI: function (id) {
    return new Promise(async (resolve, reject) => {
      try {
        if (!tonWallet.ton) {
          const tonConnectUI = new TonConnectUI({
            manifestUrl: tonConfig.manifestUrl,
            buttonRootId: id,
          });
          Vue.prototype.$set(tonWallet, "ton", tonConnectUI);
          resolve(tonConnectUI);
        } else {
          resolve();
        }
      } catch (err) {
        console.log("createTonConnectUI", err);
        reject("");
      }
    });
  },
  //转换ton address格式
  tranAddress: function (address) {
    return toUserFriendlyAddress(address);
  },
  //订阅连接状态
  unsubscribeTonConnectStatus: function (callback) {},
  //获取钱包列表
  getWalletList: function (ton) {
    return new Promise(async (resolve, reject) => {
      try {
        const ton = tonWallet.ton;
        const walletsList = await ton.getWallets();
        resolve(wallet);
      } catch (err) {
        console.log("getWalletList: ", err);
        reject(err);
      }
    });
  },
  //开启或关闭ton模态框
  openOrCloseTonModal: function () {
    return new Promise((resolve, reject) => {
      try {
        //获取当前模态框状态
        const ton = tonWallet.ton;
        console.log("tonWallet",tonWallet)
        let timer = setTimeout(async () => {
          clearTimeout(timer);
          timer = null;
          Vue.prototype.$set(tonWallet, "connected", ton.connected);
          tonWallet.connected = ton.connected;
          const modal = !tonWallet.connected ? await ton.openModal() : ton.closeModal();
          resolve(modal);
        }, 200);
      } catch (err) {
        console.log("openOrCloseTonModal: ", err);
        reject(err);
      }
    });
  },
  //获取连接信息
  getWalletInfo: function () {
    const ton = tonWallet.ton;
    const arr = ["wallet", "walletInfo", "account", "connected"];
    if (ton.account) {
      Vue.prototype.$set(tonWallet, "uiAddress", tonWallet.tranAddress(ton.account.address));
      Vue.prototype.$set(tonWallet, "address", ton.account.address);
      Vue.prototype.$set(tonWallet, "walletStateInit", ton.account.walletStateInit);
      Vue.prototype.$set(tonWallet, "publicKey", ton.account.publicKey);

      tonWallet.tranAddress(ton.account.address);
      Vue.prototype.$set(tonWallet, "chain", ton.account.chain);
    }
    arr.map((it) => Vue.prototype.$set(tonWallet, it, ton[it]));
  },
  //实时获取链上余额
  // getWalletBanlanceAndSequence: async function () {
  //   // const wallet = WalletContractV4.create({ publicKey: tonWallet.publicKey, workchain: 0 });
  //   // // initialize ton rpc client on testnet
  //   const endpoint = await getHttpEndpoint({ network: tonConfig.networktype });
  //   const client = new TonClient({ endpoint });
  //   // query balance from chain
  //   const balance = await client.getBalance(tonWallet.address);
  //   console.log("balance:", fromNano(balance));
  // },
  disconnect: async function (callback) {
    if (tonWallet.ton) {
      await tonWallet.ton.disconnect();
      tonWallet.getWalletInfo();
      tonWallet.unsubscribeTon();
      tonWallet.ton = {};
      tonWallet.connected = false
      typeof callback === "function" && callback(tonWallet);
      console.log("tonWallet disconnect:", tonWallet);
    }
  },
  //发送交易
  sendTransaction: function () {
    return new Promise(async (resolve, reject) => {
      try {
        const ton = tonWallet.ton;
        const validUntil = Math.floor(Date.now() / 1000) + 60; // 60 sec
        const messages = [
          {
            address: "EQCIxtpTMYkGnnJcklwAj2TXNPA9LhiiiBZUIuJ4FAsst5ed",
            amount: "10000000",
            // payload: beginCell().storeUint(func_id, 32).storeUint(amount, 32).endCell().toBoc().toString("base64"),
            // payload: beginCell().storeUint(0, 32).storeBuffer(Buffer.from('increment')).endCell().toBoc().toString("base64")
          },
        ];
        const transaction = {
          validUntil,
          messages,
          //transaction body
        };
        console.log("transaction", transaction);
        const result = await ton.sendTransaction(transaction);
        console.log(result.boc);
        resolve(result.boc);
      } catch (e) {
        alert("Unknown error happened", e);
      }
    });
  },
};

Vue.prototype.tonWallet = tonWallet;

export default tonWallet;
