import "core-js/modules/es.array.push.js";
import { createNamespacedHelpers } from "vuex";
const walletVuex = createNamespacedHelpers("wallet");
export default {
  name: "mNav",
  data() {
    const navList = [{
      title: "Home",
      path: "home"
    }, {
      title: "Tasks",
      path: "tasks"
    },
    // {
    //   title: "Leaderboard",
    //   path: "learderboard",
    // },
    {
      title: "Frends",
      path: "frends"
    }, {
      title: "Airdrop",
      path: "airdrop"
    }];
    return {
      navList,
      selectNav: "home"
    };
  },
  computed: {
    ...walletVuex.mapState(["isMobile", "theme", "taskCount"])
  },
  watch: {
    "$route.path": {
      handler(newPath, oldPath) {
        console.log("newPathnewPath", newPath);
        const path = newPath.split("/")[1];
        console.log("pathpathpath", path);
        this.selectNav = path;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    onNav(item) {
      this.selectNav = item.path;
      this.$nextTick(() => {
        console.log(" this.$router.push(`/${path}`);", `/${item.path}`);
        this.$router.push(`/${item.path}`);
      });
    }
  }
};